import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie'

import closeIcon from '../images/close-circle.svg'
import backgroundWatercolor from '../images/photos/background-watercolor.png'

import StyledLink from './styles/StyledLink'

const ContainerOuter = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: rgba(45, 49, 66, 0.5);
  z-index: 1002;
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;
  margin-bottom: 60px;
  z-index: 8;
  width: 80%;
  max-width: 630px;
  @media (max-width: 600px) {
    max-width: 380px;
    width: 85%;
  }
`

const ImpressumOuter = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
`

const ImpressumInner = styled.div`
  overflow-y: auto;
  background-color: white;
  box-shadow: 5px 10px 30px 0 rgba(45, 49, 66, 0.2);
  border-radius: 8px;
`

const Content = styled.div`
  padding: 60px 80px 40px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 8px;
  max-width: 80vw;
  @media (max-width: 800px) {
    padding: 50px 60px 30px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    padding: 40px 30px 30px;
    font-size: 14px;
  }
  @media (max-width: 400px) {
    padding: 40px 20px 30px;
    font-size: 14px;
  }
`

const Header = styled.h2`
  font-size: 34px;
  font-family: Montserrat, sans-serif;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
  line-height: 1.2;
  position: relative;
  @media (max-width: 500px) {
    font-size: 30px;
  }
`

const Divider = styled.div`
  margin-top: 16px;
  margin-bottom: 42px;
`

const CloseButton = styled.button`
  img {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 44px;
    height: 44px;
    z-index: 10;
    @media (max-width: 400px) {
      right: -17px;
      top: -17px;
      width: 38px;
      height: 38px;
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 32px;
`

const Input = styled.input`
  display: block;
  border: 1px solid ${p => p.theme.midGray};
  border-radius: 4px;
  padding: 11px 20px;
  width: 100%;
  margin-bottom: 20px;
`

const CheckboxContainer = styled.div`
  display: flex;
`

const SmallText = styled.label`
  position: relative;
  padding-left: 20px;
  font-size: 13px;
  margin-bottom: 20px;
  text-align: left;
  :before {
    cursor: pointer;
    content: '';
    position: absolute;
    top: 2px;
    left: -12px;
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid ${p => p.theme.midGray};
  }
`

const Checkbox = styled.input`
  :checked + ${SmallText}:before {
    background-color: ${p => p.theme.primaryColor};
    transition: 0.15s;
    border: 1px solid ${p => p.theme.primaryColor};
  }
  :checked + ${SmallText}:after {
    content: '';
    position: absolute;
    top: 4px;
    left: -8px;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    cursor: pointer;
  }
`

const WaterColor = styled.img`
  position: absolute;
  width: 230px;
  opacity: 0.4;
  z-index: 0;
  top: 20px;
  left: 20px;
  @media (max-width: 500px) {
    left: 30px;
  }
  @media (max-width: 410px) {
    left: 5px;
  }
`

class Modal extends Component {
  constructor(props) {
    super(props)
    this.buttonRef = React.createRef()
  }

  state = {
    email: '',
    dsgvo: false,
    submitted: false,
    sendSuccess: false,
    sendFail: false,
  }

  handleEmailChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  handleCheckboxChange = e => {
    this.setState({
      dsgvo: e.target.checked,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.submitted) {
      return
    }
    this.setState({
      submitted: true,
    })
    if (this.state.dsgvo === false) {
      return
    }
    const submitButton = this.buttonRef.current
    submitButton.classList.add('processing')
    const url = 'https://api.newsletter2go.com/forms/submit/nshqmh6f-uq35dpbb-n34'
    const { email } = this.state
    const data = {
      recipient: {
        email,
      },
    }
    const fetchData = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    fetch(url, fetchData)
      .then(response => response.json())
      .then(data => {
        if (
          data &&
          data.value &&
          data.value[0] &&
          data.value[0].result &&
          data.value[0].result.error &&
          data.value[0].result.error.failed === 1
        ) {
          submitButton.classList.remove('processing')
          submitButton.classList.add('fail')
          this.setState({
            sendFail: true,
          })
        } else {
          submitButton.classList.remove('processing')
          submitButton.classList.add('done')
          this.setState({
            sendSuccess: true,
          })
          Cookies.set('showModal', 'false', { expires: 1825 })
        }
      })
      .catch(error => {
        submitButton.classList.remove('processing')
        submitButton.classList.add('fail')
        this.setState({
          sendFail: true,
        })
      })
  }

  render() {
    return (
      <ContainerOuter>
        <Container>
          <ImpressumOuter>
            <ImpressumInner>
              <Content>
                <WaterColor alt="" src={backgroundWatercolor} />
                <Header>Begleiten Sie uns durch das Weinjahr</Header>
                <Divider />
                <p>
                  Bleiben Sie am Laufenden und erfahren Sie mehr über das Weingut, unsere Weine, sowie Events und
                  besondere Aktionen!
                </p>
                <br />
                <p>
                  Tragen Sie hier Ihre E-Mail Adresse ein und schon landet unsere nächste Flaschenpost in Ihrem
                  Posteingang.
                </p>
                <br />
                <p>
                  Wir behandeln Ihre{' '}
                  <Link to="/Datenschutzerklaerung">
                    <StyledLink>Daten</StyledLink>
                  </Link>{' '}
                  respektvoll und geben diese nicht an Dritte weiter.
                </p>
                <Form onSubmit={this.handleSubmit}>
                  <Input
                    type="email"
                    id="modal-email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    required
                    placeholder="E-Mail-Adresse"
                    aria-label="E-Mail"
                  />
                  <CheckboxContainer>
                    <Checkbox type="checkbox" id="modal-dsgvo" required onChange={this.handleCheckboxChange} />
                    <SmallText htmlFor="modal-dsgvo">
                      Hiermit willige ich ein, den Newsletter der Weingut Edith Göschl GesbR an die genannte
                      E-Mail-Adresse zu erhalten. Ich kann meine Einwilligung jederzeit widerrufen. Weitere
                      Informationen finden Sie{' '}
                      <StyledLink>
                        <Link to="/Datenschutzerklaerung">hier</Link>
                      </StyledLink>
                      .
                    </SmallText>
                  </CheckboxContainer>
                  <div className="spinning-button-wrapper">
                    <button type="submit" className="spin" id="spin" ref={this.buttonRef}>
                      <span className="spinning-senden">ANMELDEN</span>
                      <span className="spinning-check">
                        <svg viewBox="0 0 24 24">
                          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                      </span>
                      <span className="spinning-x">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <line
                            y1="-1"
                            x2="16.9706"
                            y2="-1"
                            transform="translate(18 5) rotate(135)"
                            stroke="white"
                            strokeWidth="2"
                          />
                          <line
                            y1="-1"
                            x2="16.9706"
                            y2="-1"
                            transform="translate(19 17) rotate(-135)"
                            stroke="white"
                            strokeWidth="2"
                          />
                        </svg>
                      </span>
                    </button>
                    {this.state.sendSuccess && (
                      <span className="success-text">
                        Vielen Dank für die Anmeldung. Wir haben Ihnen eine E-Mail mit einem Bestätigungslink
                        zugeschickt.
                      </span>
                    )}
                    {this.state.sendFail && (
                      <span className="fail-text">
                        Leider ist ein Fehler aufgetreten. Bitte schicken Sie eine E-Mail an{' '}
                        <StyledLink as="a" style={{ whiteSpace: 'nowrap' }} href="mailto:office@weingut-goeschl.at">
                          office@weingut-goeschl.at
                        </StyledLink>{' '}
                        um sich für den Newsletter anzumelden.
                      </span>
                    )}
                  </div>
                </Form>
                <CloseButton onClick={this.props.closeModal}>
                  <img src={closeIcon} alt="Schließen" />
                </CloseButton>
              </Content>
            </ImpressumInner>
          </ImpressumOuter>
        </Container>
      </ContainerOuter>
    )
  }
}

export default Modal
